import React, { useState, useEffect } from 'react';
import {
    Button,
    Card,
    Checkbox,
    Header,
    Icon,
    Modal,
    Popup,
} from 'semantic-ui-react';

import { BaseControlledModal, ModalAction } from '../modals/modals';
import { colors } from '../../utils/colors';
import { sports, sportsAlphabetized } from '../../utils/sports';
import { Mutation } from 'react-apollo';
import { orgUpdateMutation } from '../../gql/org';
import { userUpdateMutation } from '../../gql/user';
import { InfoModalVideo } from '../utilityComponents/InfoModalVideo';
import { useMutation } from 'react-apollo-hooks';

let PermissionHeader = (props) => {
    return (
        <div
            style={{
                marginBottom: '15px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                fontWeight: 'bold',
            }}
        >
            <div
                style={{
                    width: '100px',
                    textAlign: 'center',
                }}
            >
                Can read?
            </div>
            <div
                style={{
                    width: '100px',
                    textAlign: 'center',
                }}
            >
                Can write?
            </div>
            <div>Sport</div>
        </div>
    );
};

export let createPermissionsObjFromOrg = (
    is_admin,
    permissions,
    parsedSports
) => {
    let orgSports;
    if (parsedSports && parsedSports.length > 0) {
        orgSports = parsedSports;
    } else {
        orgSports = Object.keys(sports);
    }
    if (permissions) {
        let permissionsObj = JSON.parse(permissions);
        let orgPermissions = {};
        orgSports.forEach((s) => {
            if (permissionsObj[s]) {
                orgPermissions[s] = {
                    read: permissionsObj[s] && permissionsObj[s].read,
                    write: permissionsObj[s] && permissionsObj[s].write,
                };
            } else {
                orgPermissions[s] = {
                    read: false,
                    write: false,
                };
            }
        });
        return orgPermissions;
    } else {
        let obj = {
            all: {
                read: false,
                write: false,
            },
        };
        orgSports.forEach((sport) => {
            obj[sport] = {
                read: false,
                write: false,
            };
        });
        return obj;
    }
};

export let createPermissionsObj = (is_admin, permissions, parsedSports) => {
    let orgSports;
    if (parsedSports && parsedSports.length > 0) {
        orgSports = parsedSports;
    } else {
        orgSports = Object.keys(sports);
    }
    if (is_admin) {
        let obj = {
            // all: {
            //     read: true,
            //     write: true,
            // }
        };
        orgSports.forEach((sport) => {
            obj[sport] = {
                read: true,
                write: true,
            };
        });
        return obj;
    } else if (permissions) {
        let permissionsObj = JSON.parse(permissions);

        if (Object.keys(permissionsObj).length < orgSports.length) {
            orgSports.forEach((s) => {
                if (!permissionsObj[s]) {
                    permissionsObj[s] = {
                        read: false,
                        write: false,
                    };
                }
            });
        }
        return permissionsObj;
    } else {
        let obj = {
            // all: {
            //     read: false,
            //     write: false,
            // }
        };
        orgSports.forEach((sport) => {
            obj[sport] = {
                read: false,
                write: false,
            };
        });
        return obj;
    }
};

export let createPermissionsModalObj = (
    is_admin,
    permissions,
    parsedSports,
    isNewUser = false
) => {
    let orgSports;
    if (parsedSports && parsedSports.length > 0) {
        orgSports = parsedSports;
    } else {
        orgSports = Object.keys(sports);
    }
    if (permissions) {
        let permissionsObj = JSON.parse(permissions);

        if (Object.keys(permissionsObj).length < orgSports.length) {
            orgSports.forEach((s) => {
                if (!permissionsObj[s]) {
                    permissionsObj[s] = {
                        read: false,
                        write: false,
                    };
                }
            });
        }
        return permissionsObj;
    } else {
        let obj = {
            // all: {
            //     read: false,
            //     write: false,
            // }
        };
        orgSports.forEach((sport) => {
            obj[sport] = {
                read: isNewUser,
                write: false,
            };
        });
        return obj;
    }
};

let updateUserPermissions: (
    updateUser: any,
    props: {
        sport?: any;
        read?: any;
        write?: any;
        userPermissions?: any;
        user?: any;
        refetch?: any;
        selectAll?: any;
        isAllReadSelected?: any;
        isAllWriteSelected?: any;
    }
) => any = (
    updateUser,
    {
        sport,
        read,
        write,
        userPermissions,
        user,
        refetch,
        selectAll,
        isAllReadSelected,
        isAllWriteSelected,
    }
) => {
    let userPermissionsCopy = { ...userPermissions };
    if (selectAll === 'read') {
        Object.keys(userPermissionsCopy).forEach((s) => {
            userPermissionsCopy[s].read = isAllReadSelected ? false : true;
        });

        Object.keys(userPermissionsCopy).forEach((s) => {
            userPermissionsCopy[s].write = isAllReadSelected
                ? false
                : userPermissionsCopy[s].write;
        });
    } else if (selectAll === 'write') {
        Object.keys(userPermissionsCopy).forEach((s) => {
            userPermissionsCopy[s].read = isAllWriteSelected
                ? userPermissionsCopy[s].read
                : true;
        });

        Object.keys(userPermissionsCopy).forEach((s) => {
            userPermissionsCopy[s].write = isAllWriteSelected ? false : true;
        });
    } else {
        userPermissionsCopy[sport] = {
            read,
            write,
        };
    }

    updateUser({
        variables: {
            id: user.id,
            email: user.email,
            permissions: JSON.stringify(userPermissionsCopy),
        },
    }).then(() => {
        refetch();
    });
    return userPermissionsCopy;
};

let Admin = (props) => {
    let { is_admin, onChange } = props;
    return (
        <div
            style={{
                marginBottom: '15px',
                marginTop: '15px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
            }}
        >
            <div
                style={{
                    width: '100px',
                    textAlign: 'center',
                }}
            >
                <Checkbox
                    toggle
                    checked={is_admin}
                    onClick={() => {
                        onChange({
                            is_admin: !is_admin,
                        });
                    }}
                />
            </div>
            <div>{`Make this user an Organization Administrator`}</div>
        </div>
    );
};

let Permission = (props) => {
    let { read, write, readOnly, sport, onChange } = props;
    let sportLabel = sportsAlphabetized[sport];
    return sport === 'all' ? null : (
        <div
            style={{
                marginBottom: '15px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
            }}
        >
            <div
                style={{
                    width: '100px',
                    textAlign: 'center',
                }}
            >
                <Checkbox
                    toggle
                    checked={read}
                    disabled={readOnly}
                    onClick={() => {
                        onChange({
                            read: !read,
                            write: read && write ? !write : write,
                        });
                    }}
                />
            </div>
            <div
                style={{
                    width: '100px',
                    textAlign: 'center',
                }}
            >
                <Checkbox
                    toggle
                    checked={write}
                    disabled={readOnly}
                    onClick={() => {
                        onChange({
                            read: !read ? !read : read,
                            write: !write,
                        });
                    }}
                />
            </div>
            <div>{sportLabel}</div>
        </div>
    );
};

export let UserPermissionsModal = (props) => {
    let {
        permissionsModalVisible,
        onClose,
        org = {},
        refetch,
        readOnly,
        isNewUser,
    } = props;
    let { sports: unparsedSports = '[]' } = org;
    let parsedSports = JSON.parse(unparsedSports);
    let [user, setUser] = useState(permissionsModalVisible);

    useEffect(() => {
        if (permissionsModalVisible && permissionsModalVisible !== user) {
            setUser(permissionsModalVisible);
        }
    }, [permissionsModalVisible]);

    let { permissions, is_admin } = user || {};
    let defaultUserPermissions = readOnly
        ? createPermissionsObjFromOrg(is_admin, permissions, parsedSports)
        : createPermissionsModalObj(
              is_admin,
              permissions,
              parsedSports,
              isNewUser
          );
    let [userPermissions, setUserPermissions] = useState(
        defaultUserPermissions
    );
    let [isAdmin, setIsAdmin] = useState(is_admin);
    const updateUser = useMutation(userUpdateMutation);

    useEffect(() => {
        if (readOnly) {
            setUserPermissions(
                createPermissionsObjFromOrg(is_admin, permissions, parsedSports)
            );
        } else {
            setUserPermissions(
                createPermissionsModalObj(
                    is_admin,
                    permissions,
                    parsedSports,
                    isNewUser
                )
            );
            setIsAdmin(is_admin);
        }
    }, [permissions, is_admin]);

    useEffect(() => {
        if (isNewUser) {
            if (!permissions && permissionsModalVisible.id) {
                let newPermission = createPermissionsModalObj(
                    false,
                    null,
                    parsedSports,
                    true
                );
                props.updateUser({
                    variables: {
                        id: permissionsModalVisible.id,
                        email: permissionsModalVisible.email,
                        permissions: JSON.stringify(newPermission),
                    },
                });
                setUserPermissions(newPermission);
            }
        }
    }, [isNewUser]);

    if (!user) {
        return null;
    }

    let isAllReadSelected = true;
    let isAllWriteSelected = true;
    Object.keys(userPermissions).forEach((sport) => {
        if (sport !== 'all' && userPermissions[sport]) {
            if (!userPermissions[sport].read) {
                isAllReadSelected = false;
            }
            if (!userPermissions[sport].write) {
                isAllWriteSelected = false;
            }
        }
    });

    let sportsToShowAlphabetized = Object.keys(userPermissions).sort((a, b) => {
        return sportsAlphabetized[a] < sportsAlphabetized[b] ? -1 : 1;
    });
    let sportsToShow = (updateUser) =>
        sportsToShowAlphabetized.map((sport, index) => {
            return (
                <Permission
                    key={index}
                    sport={sport}
                    readOnly={readOnly}
                    {...userPermissions[sport]}
                    onChange={(update) => {
                        setUserPermissions(
                            updateUserPermissions(updateUser, {
                                ...update,
                                sport,
                                userPermissions,
                                user,
                                refetch,
                            })
                        );
                        // updateUserPermissions({...update, sport, userPermissions});
                    }}
                />
            );
        });
    console.log({ isAdmin });
    return (
        <Modal size="small" open={!!permissionsModalVisible} onClose={onClose}>
            <Modal.Header>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    {permissionsModalVisible
                        ? `User Permissions - ${
                              permissionsModalVisible.firstname
                                  ? `${permissionsModalVisible.firstname} ${permissionsModalVisible.lastname}`
                                  : permissionsModalVisible.email
                          }`
                        : `User Permissions`}
                    <InfoModalVideo
                        {...{
                            videoId: 'KfPi7aA0-OM',
                            header: 'Set user permissions',
                        }}
                    />
                </div>
            </Modal.Header>
            <Modal.Content>
                {readOnly ? null : (
                    <div
                        style={{
                            marginBottom: '25px',
                        }}
                    >
                        <div style={{ marginBottom: '10px' }}>
                            Organization Administrators have full access for all
                            sports, as well as authority to set your
                            organization’s sports, control permission levels for
                            other users, and set your organization’s primary
                            contact for receiving contracts. Your organization
                            may have multiple Administrators. Before you make
                            another user an Administrator, please be sure they
                            understand what they are (and are not) allowed to do
                            under your organization’s internal policies.
                        </div>
                        <Admin
                            is_admin={isAdmin}
                            onChange={(update) => {
                                setIsAdmin(update.is_admin);
                                updateUser({
                                    variables: {
                                        id: user.id,
                                        email: user.email,
                                        ...update,
                                    },
                                }).then(() => {
                                    refetch();
                                });
                            }}
                        />
                    </div>
                )}

                {isAdmin ? null : (
                    <div>
                        <div
                            style={{
                                marginBottom: '15px',
                            }}
                        >
                            {readOnly
                                ? `Below you can view your permissions by sport. If you feel that your permissions are incorrect, contact your organization's administrator.`
                                : `For non-Administrator users, you may set permissions on a sport-by-sport basis.  Each sport has two toggle switches: the left one controls whether 
                                the user can view contracts in that sport, and the right one controls whether the user can create/edit/send contracts in that sport.`}
                        </div>
                        <div>
                            {readOnly ? null : (
                                <div
                                    style={{
                                        flexDirection: 'row',
                                        display: 'flex',
                                        marginBottom: '10px',
                                    }}
                                >
                                    <Button
                                        onClick={() => {
                                            let update = {};
                                            setUserPermissions(
                                                updateUserPermissions(
                                                    updateUser,
                                                    {
                                                        ...update,
                                                        sport: undefined,
                                                        userPermissions,
                                                        user,
                                                        refetch,
                                                        selectAll: 'read',
                                                        isAllReadSelected,
                                                        isAllWriteSelected,
                                                    }
                                                )
                                            );
                                        }}
                                    >
                                        {isAllReadSelected
                                            ? `Uncheck all sport Can Read`
                                            : `Check all sport Can Read`}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            let update = {};
                                            setUserPermissions(
                                                updateUserPermissions(
                                                    updateUser,
                                                    {
                                                        ...update,
                                                        sport: undefined,
                                                        userPermissions,
                                                        user,
                                                        refetch,
                                                        selectAll: 'write',
                                                        isAllReadSelected,
                                                        isAllWriteSelected,
                                                    }
                                                )
                                            );
                                        }}
                                    >
                                        {isAllWriteSelected
                                            ? `Uncheck all sport Can Write`
                                            : `Check all sport Can Write`}
                                    </Button>
                                </div>
                            )}

                            <PermissionHeader />

                            {sportsToShow(updateUser)}
                        </div>
                    </div>
                )}
            </Modal.Content>
        </Modal>
    );
};

export let UserUpdateWrapper = (props) => (
    <Mutation mutation={userUpdateMutation}>
        {(userUpdate) => props.children(userUpdate)}
    </Mutation>
);
