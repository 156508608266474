import React, { Component, useState, useEffect } from 'react';
import {
    Button,
    Checkbox,
    Dropdown,
    Header,
    Popup,
    Sticky,
} from 'semantic-ui-react';
import { Query } from 'react-apollo';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { CSVLink } from 'react-csv';

import { contestsQuery } from '../../gql/contest';

import { sportsDefaultQuery } from '../../gql/defaults';

import {
    sports,
    sportDropdownOptions,
    sportSelectOptions,
} from '../../utils/sports';
import {
    statusDescriptions,
    statuses,
    statusIcons,
} from '../../utils/statuses';
import { getDateDiffInDays } from '../../utils/helper';

import ContestHistoryModal from './contestHistoryModal';
import CreateContestModal from '../modals/createContestModal';
import EditContestModal from '../modals/editContestModal';
import ViewSignedModal from '../modals/viewSignedModal';
import SubmitContestModalContainer from '../modals/submitContestModalContainer';
import Spinner from '../utilityComponents/spinner';
import { BaseControlledModal, BaseModal, ModalSelect } from '../modals/modals';
import ConditionalPopup from '../utilityComponents/ConditionalPopup';
import ContestStatusModalContainer from '../modals/contestStatusModal';
import { RequestContractModal } from '../modals/requestContractModal';

export let timeString = (hour, minute) => {
    return hour === 24
        ? `TBA`
        : `${hour % 12 === 0 ? 12 : hour % 12}:${
              minute < 10 ? `0${minute}` : minute
          } ${hour >= 12 ? 'PM' : 'AM'}`;
};

const getDayDate = (gameDate) => {
    let date = new Date(gameDate);
    return {
        day: days[date.getUTCDay()],
        date: `${
            date.getUTCMonth() + 1
        }/${date.getUTCDate()}/${date.getUTCFullYear()}`,
    };
};

const formatContestForCSV = ({ contest, orgs, user }) => {
    let [homeOrAway, opponent] = getOpponentString({ contest, orgs, user });
    let { day, date } = getDayDate(contest.date);
    return {
        sport: sports[contest.sport],
        day,
        date,
        homeOrAway,
        opponent,
        time: timeString(contest.hour, contest.minute),
        location: contest.location,
    };
};

const formatContestsForCsv = ({ contests, orgs, user }) => {
    return contests.map((contest) => {
        return formatContestForCSV({ contest, orgs, user });
    });
};

const headers = [
    { label: 'Sport', key: 'sport' },
    { label: 'Day', key: 'day' },
    { label: 'Date', key: 'date' },
    { label: 'Home or Away', key: 'homeOrAway' },
    { label: 'Opponent', key: 'opponent' },
    { label: 'Time', key: 'time' },
    { label: 'Location', key: 'location' },
];

const ScheduleActions = ({
    contests = [],
    permissions,
    user,
    orgs,
    history,
    refetch,
}) => {
    let [formattedContests, setFormattedContests] = useState(contests);

    useEffect(() => {
        setFormattedContests(formatContestsForCsv({ contests, user, orgs }));
    }, [contests]);

    let canCreate =
        user.is_admin ||
        Object.values(permissions).some((sport) => sport.write);

    return (
        <div
            style={{
                marginBottom: '25px',
            }}
        >
            <div style={{ padding: '5px 0' }}>
                <Button fluid onClick={() => refetch()}>
                    Refresh Schedule
                </Button>
            </div>
            <div>
                <Query
                    query={sportsDefaultQuery}
                    variables={{
                        orgid: user.orgid,
                    }}
                >
                    {({ data, loading, error }) => {
                        if (loading || error) {
                            return null;
                        }
                        let sportsDefault = JSON.parse(
                            data.sportsDefault.properties
                        );
                        return (
                            <ConditionalPopup
                                hasPopup={!canCreate}
                                position="top center"
                                content={
                                    <div>
                                        You do not have permission to create
                                        contests. For access, please contact
                                        your administrator.
                                    </div>
                                }
                                trigger={
                                    <div>
                                        <CreateContestModal
                                            canCreate={canCreate}
                                            orgs={orgs}
                                            user={user}
                                            permissions={permissions}
                                            sportsDefault={sportsDefault}
                                            refetchContests={() => refetch()}
                                            actions={[
                                                {
                                                    title: 'Create Contest',
                                                    key: 'create_contest',
                                                    primary: true,
                                                    disabled: !canCreate,
                                                    onClick: () => {
                                                        console.log(
                                                            'create contest'
                                                        );
                                                    },
                                                },
                                            ]}
                                        />
                                    </div>
                                }
                                on="hover"
                            />
                        );
                    }}
                </Query>
            </div>
            <div
                style={{
                    paddingTop: '5px',
                }}
            >
                <ConditionalPopup
                    hasPopup={!canCreate}
                    position="top center"
                    content={
                        <div>
                            You do not have permission to create contests. For
                            access, please contact your administrator.
                        </div>
                    }
                    trigger={
                        <div>
                            <Button
                                disabled={!canCreate}
                                fluid
                                primary
                                onClick={() => history.push('bulk')}
                            >
                                Bulk Create Contests
                            </Button>
                        </div>
                    }
                    on="hover"
                />
            </div>

            <div
                style={{
                    marginTop: '5px',
                }}
            >
                <CSVLink
                    headers={headers}
                    data={formattedContests}
                    filename="Perfect-Record-export.csv"
                >
                    <Button fluid positive>
                        Export Visible Schedule
                    </Button>
                </CSVLink>
            </div>
        </div>
    );
};

const academicYears = [
    2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029,
    2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041,
    2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050,
];
const academicYearOptions = academicYears.map((year) => ({
    label: `${year - 1}-${year}`,
    value: year,
}));
const homeOrAwayOptions = [
    { label: 'All', value: '' },
    { label: 'Away', value: 'away' },
    { label: 'Home', value: 'home' },
];
const seasonOptions = [
    { label: 'Fall', value: 'FALL' },
    { label: 'Winter', value: 'WINTER' },
    { label: 'Spring', value: 'SPRING' },
    { label: 'Summer', value: 'SUMMER' },
];
const statusOptions = Object.keys(statusDescriptions).map((status) => ({
    value: status,
    label: statusDescriptions[status],
}));

export const FilterSelect = (props) => {
    let styles = {
        control: (base) => ({
            ...base,
            background: 'white',
        }),
        valueContainer: (base) => ({
            ...base,
            paddingLeft: '12px',
            paddingRight: '12px',
        }),
    };
    return (
        <Select
            name={props.name}
            isMulti={props.isMulti}
            isSearchable={!props.notSearchable}
            isClearable={false}
            placeholder={props.placeholder}
            value={props.value}
            options={props.options}
            styles={styles}
            onChange={props.onChange}
        />
    );
};

const ScheduleFilters = ({
    permissions,
    user,
    filters,
    updateFilter,
    resetFilters,
    opponentOptions,
}) => {
    let sportsFilterOptions = !permissions
        ? sportSelectOptions
        : sportSelectOptions.filter((option) => {
              return (
                  permissions[option.value] && permissions[option.value].read
              );
          });
    return (
        <div
            style={{
                textAlign: 'left',
            }}
        >
            <Header as="h3">Schedule Filters</Header>
            <div style={filterDivStyles}>
                <Checkbox
                    label={'Show past contests'}
                    checked={filters.past}
                    onChange={(event, data) => {
                        updateFilter('past', data.checked);
                    }}
                />
            </div>
            <div style={filterDivStyles}>
                <FilterSelect
                    name="academicYears"
                    placeholder="Academic Years"
                    isMulti={true}
                    notSearchable
                    value={filters.academicYears}
                    options={academicYearOptions}
                    onChange={(value) => updateFilter('academicYears', value)}
                />
            </div>
            <div style={filterDivStyles}>
                <FilterSelect
                    name="seasons"
                    placeholder="Seasons"
                    isMulti={true}
                    value={filters.seasons}
                    options={seasonOptions}
                    onChange={(value) => updateFilter('seasons', value)}
                />
            </div>
            <div style={filterDivStyles}>
                <FilterSelect
                    name="sports"
                    placeholder="Sports"
                    isMulti={true}
                    value={filters.sports}
                    options={sportsFilterOptions}
                    onChange={(value) => updateFilter('sports', value)}
                />
            </div>
            <div style={filterDivStyles}>
                <FilterSelect
                    name="statuses"
                    placeholder="Contract Status"
                    isMulti={true}
                    value={filters.statuses}
                    options={statusOptions}
                    onChange={(value) => updateFilter('statuses', value)}
                />
            </div>
            <div style={filterDivStyles}>
                <FilterSelect
                    name="homeOrAway"
                    placeholder="Home/Away"
                    value={filters.homeOrAway}
                    options={homeOrAwayOptions}
                    onChange={(value) => updateFilter('homeOrAway', value)}
                />
            </div>
            <div style={filterDivStyles}>
                <FilterSelect
                    name="opponents"
                    placeholder="Opponents"
                    isMulti={true}
                    value={filters.opponents}
                    options={opponentOptions}
                    onChange={(value) => updateFilter('opponents', value)}
                />
            </div>
            <div style={filterDivStyles}>
                <Button
                    onClick={() => {
                        resetFilters();
                    }}
                    fluid
                >
                    Clear Filters
                </Button>
            </div>
        </div>
    );
};

const filterDivStyles = {
    padding: '3px 0',
};

const ScheduleGridHeader = ({ lastActivity }) => (
    <div
        style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '0 5px',
        }}
    >
        <div style={{ flex: 2, textAlign: 'left' }}>
            <Header as="h4">Sport</Header>
        </div>
        <div style={{ flex: 1, textAlign: 'left' }}>
            <Header as="h4">Date</Header>
        </div>
        {lastActivity ? (
            <div style={{ flex: 1 }}>
                <Header as="h4">Last Activity</Header>
            </div>
        ) : null}
        <div style={{ flex: 4, textAlign: 'left', paddingLeft: '25px' }}>
            <Header as="h4" style={{ paddingLeft: '15px' }}>
                Opponent
            </Header>
        </div>
        <div style={{ flex: 1, textAlign: 'left' }}>
            <Header as="h4">Time</Header>
        </div>
        <div style={{ flex: 1 }}>
            <Header as="h4">Status</Header>
        </div>
        <div style={{ flex: 4 }}>
            <Header as="h4">Actions</Header>
        </div>
    </div>
);

export let getOpponentString = ({ user, contest, orgs }) => {
    let isHome = user.orgid === contest.orgid;
    let opponentStr = isHome
        ? `${orgs[contest.away_orgid]}`
        : `${orgs[contest.orgid]}`;
    if (
        contest.away_placeholder_description &&
        [
            statuses.AWAY_PLACEHOLDER,
            statuses.AWAY_PLACEHOLDER_PAPER_RECEIVED,
        ].includes(contest.status)
    ) {
        opponentStr += ` (${contest.away_placeholder_description})`;
    }
    return [!isHome ? 'A' : 'H', opponentStr];
};

let OpponentString = ({ user, contest, orgs }) => {
    let isHome = user.orgid === contest.orgid;
    let opponentStr = isHome
        ? `${orgs[contest.away_orgid]}`
        : `${orgs[contest.orgid]}`;
    if (
        contest.away_placeholder_description &&
        [
            statuses.AWAY_PLACEHOLDER,
            statuses.AWAY_PLACEHOLDER_PAPER_RECEIVED,
        ].includes(contest.status)
    ) {
        opponentStr += ` (${contest.away_placeholder_description})`;
    }
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}
        >
            <div style={{ width: '15px' }}>{!isHome && '@'}</div>
            <span>{opponentStr}</span>
        </div>
    );
};

export let ActionButton = ({ title, ...props }) => {
    return (
        <div style={{ display: 'flex', flex: 1 }}>
            <Button {...props} fluid>
                {title}
            </Button>
        </div>
    );
};

const checkPermission = (
    user,
    permission = 'write',
    permissions = {},
    sport
) => {
    return (
        user.is_admin ||
        (permissions[sport] &&
            permissions[sport] &&
            permissions[sport][permission])
    );
};

let getButtons = ({
    mine,
    status,
    home,
    contest,
    history,
    user,
    permissions,
}) => {
    let buttons = [];
    if (mine) {
        if (
            [
                statuses.AWAY_PLACEHOLDER,
                statuses.AWAY_PLACEHOLDER_PAPER_RECEIVED,
            ].includes(status)
        ) {
            buttons.push({
                title: 'Edit Away Placeholder',
                modal: 'editContestModalVisible',
                contest,
                disabled: !checkPermission(
                    user,
                    'write',
                    permissions,
                    contest.sport
                ),
            });
        } else {
            buttons.push({
                title: 'Edit Details',
                modal: 'editContestModalVisible',
                contest,
                disabled: !checkPermission(
                    user,
                    'write',
                    permissions,
                    contest.sport
                ),
            });
        }
        if (
            home &&
            ![
                statuses.SIGNED,
                statuses.AWAY_PLACEHOLDER,
                statuses.AWAY_PLACEHOLDER_PAPER_RECEIVED,
                statuses.HOME_PAPER_CONTRACT_RECEIVED,
            ].includes(status)
        ) {
            if (status === statuses.EDITED) {
                buttons.push({
                    title: 'Send Contract',
                    modal: 'submitContestModalVisible',
                    contest,
                    disabled: !checkPermission(
                        user,
                        'write',
                        permissions,
                        contest.sport
                    ),
                });
            } else {
                buttons.push({
                    title: 'Contract Status',
                    modal: 'contractStatusModalVisible',
                    contest,
                    disabled: !checkPermission(
                        user,
                        'write',
                        permissions,
                        contest.sport
                    ),
                });
            }
        }
    } else {
        if (!home) {
            if (
                [
                    statuses.SUBMITTED_NOTCLICKED,
                    statuses.SUBMITTED,
                    statuses.SUBMITTED_INTERNAL_AWAY,
                    statuses.SUBMITTED_INTERNAL_AWAY_COMPLETE,
                    statuses.SUBMITTED_AWAY_DIFFERENT_SIGNER,
                ].includes(contest.status) &&
                !(
                    contest.paper_contract_key &&
                    contest.paper_contract_key.length > 0
                )
            ) {
                buttons.push({
                    title: 'Sign Contract',
                    modal: 'signContestModalVisible',
                    contest,
                    onClick: () => {
                        history.push(
                            `/sign?guid=${contest.guid}&email_link=true&away_signer=true`
                        );
                    },
                    disabled: !checkPermission(
                        user,
                        'write',
                        permissions,
                        contest.sport
                    ),
                });
            }
        }
    }
    if (status === statuses.SIGNED) {
        buttons.push({
            title: 'View Signed Contract',
            modal: 'viewSignedModalVisible',
            contest: Object.assign({}, contest, { onPlatform: true }),
            disabled: !checkPermission(
                user,
                'read',
                permissions,
                contest.sport
            ),
        });
    }
    if (
        contest.paper_contract_key &&
        contest.paper_contract_key.length > 0 &&
        [
            statuses.HOME_PAPER_CONTRACT_RECEIVED,
            statuses.AWAY_PLACEHOLDER_PAPER_RECEIVED,
        ].includes(contest.status)
    ) {
        buttons.push({
            title: 'View Signed Contract',
            modal: 'viewSignedModalVisible',
            contest,
            disabled: !checkPermission(
                user,
                'read',
                permissions,
                contest.sport
            ),
        });
    } else if (
        [statuses.HOME_PAPER_CONTRACT_RECEIVED].includes(contest.status) &&
        !contest.paper_contract_key
    ) {
        buttons.push({
            custom: (props) => {
                return <HomeReceivedNotUploaded {...props} />;
            },
        });
    }
    return buttons;
};

let HomeReceivedNotUploaded = ({ key }) => {
    return (
        <div
            style={{
                flex: 1,
            }}
        >
            <Popup
                position="top center"
                trigger={
                    <Button
                        basic
                        fluid
                        style={{
                            cursor: 'default',
                        }}
                    >
                        {`Copy not uploaded`}
                    </Button>
                }
                content={
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                textAlign: 'center',
                            }}
                        >
                            <span>
                                {`This contract was signed and completed outside of the Perfect Record platform. The home school has the option of uploading a copy of the signed contract to our platform, but they have not done so yet.  If they do upload a copy of the signed contract, you will be able to view it.`}
                            </span>
                        </div>
                    </div>
                }
            />
        </div>
    );
};

let ActionButtons = ({ user, contest, history, permissions, onShowModal }) => {
    let buttons = getButtons({
        mine: user.orgid === contest.creating_orgid,
        status: contest.status,
        home: user.orgid === contest.orgid,
        contest,
        history,
        user,
        permissions,
    });

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
            }}
        >
            {buttons.map((button, index) => {
                return button.custom ? (
                    button.custom({ key: index })
                ) : (
                    <ConditionalPopup
                        key={index}
                        hasPopup={button.disabled}
                        position="top center"
                        content={
                            <div>
                                You do not have permission to access this
                                portion of the application. For access, please
                                contact your administrator.
                            </div>
                        }
                        trigger={
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                }}
                            >
                                <ActionButton
                                    key={index}
                                    onClick={() => {
                                        onShowModal(
                                            button.modal,
                                            button.contest
                                        );
                                    }}
                                    {...button}
                                />
                            </div>
                        }
                    />
                );
            })}
        </div>
    );
};

let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export let formatDate = (gameDate, hideDay = false) => {
    let date = new Date(gameDate);
    return `${hideDay ? '' : `${days[date.getUTCDay()]} `}${
        date.getUTCMonth() + 1
    }/${date.getUTCDate()}/${date.getUTCFullYear()}`;
};

const ScheduleGridRow = ({
    index,
    contest,
    lastActivity,
    user,
    orgs,
    permissions,
    history,
    onShowModal,
    contestsRefetch,
}) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: index % 2 ? '#f4f4f4' : 'white',
                padding: '5px',
            }}
        >
            <div style={{ flex: 2, textAlign: 'left' }}>
                <div
                    style={{
                        paddingRight: '15px',
                    }}
                >
                    {sports[contest.sport]}
                </div>
            </div>
            <div style={{ flex: 1, textAlign: 'left' }}>
                {formatDate(contest.date)}
            </div>
            {lastActivity ? (
                <div style={{ flex: 1 }}>
                    {`${getDateDiffInDays(
                        new Date(contest.last_activity)
                    )} days`}
                </div>
            ) : null}
            <div style={{ flex: 4, textAlign: 'left', paddingLeft: '25px' }}>
                <OpponentString {...{ user, contest, orgs }} />
            </div>
            <div style={{ flex: 1, textAlign: 'left' }}>
                {timeString(contest.hour, contest.minute)}
            </div>
            <div style={{ flex: 1, alignItems: 'center' }}>
                <ContestHistoryModal
                    contest={contest}
                    contestsRefetch={contestsRefetch}
                    openStatusModal={() => {
                        onShowModal('contractStatusModalVisible', contest);
                    }}
                    openRequestContractModal={() => {
                        onShowModal('requestContractModalVisible', contest);
                    }}
                />
            </div>
            <div
                style={{
                    flex: 4,
                }}
            >
                <ActionButtons
                    user={user}
                    contest={contest}
                    history={history}
                    permissions={permissions}
                    onShowModal={onShowModal}
                />
            </div>
        </div>
    );
};

export const ScheduleGrid = ({
    contests,
    emptyMessage,
    lastActivity,
    history,
    user,
    orgs,
    permissions,
    onShowModal,
    contestsRefetch,
}) => {
    if (contests && contests.length === 0) {
        return (
            <div>
                {emptyMessage ||
                    `No contests match your selected Schedule Filters`}
            </div>
        );
    }
    return (
        <div>
            <ScheduleGridHeader lastActivity={lastActivity} />
            {contests.map((contest, index) => (
                <ScheduleGridRow
                    key={index}
                    history={history}
                    index={index}
                    lastActivity={lastActivity}
                    user={user}
                    orgs={orgs}
                    contest={contest}
                    permissions={permissions}
                    onShowModal={onShowModal}
                    contestsRefetch={contestsRefetch}
                />
            ))}
        </div>
    );
};

class Schedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editContestModalVisible: false,
            editingContest: {},
            submitContestModalVisible: false,
            contractStatusModalVisible: false,
            requestContractModalVisible: false,
        };
    }

    notify = () => {
        toast.success('Success Notification !', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 4000,
            hideProgressBar: true,
        });
    };

    handleContextRef = (contextRef) => this.setState({ contextRef });

    updateFilter = (filter, value, refetch) => {
        this.props.updateSearchFilter({ [filter]: value });
    };

    resetFilters = () => {
        this.props.updateSearchFilter({
            academicYears: [{ label: `${2022 - 1}-${2022}`, value: 2022 }],
            homeOrAway: '',
            opponents: [],
            past: true,
            seasons: [],
            sports: [],
            statuses: [],
        });
    };

    onShowModal = (modal, contest, contestsRefetch) => {
        this.setState({
            [modal]: true,
            editingContest: contest,
            contestsRefetch,
        });
    };

    render() {
        let {
            opponentOptions,
            permissions,
            searchFilters,
            user,
            orgs,
            history,
        } = this.props;
        const {
            contextRef,
            editContestModalVisible,
            editingContest,
            submitContestModalVisible,
            contractStatusModalVisible,
            requestContractModalVisible,
            viewSignedModalVisible,
        } = this.state;
        let academicYearsFilterValues = searchFilters.academicYears
            ? searchFilters.academicYears.map((year) => year.value)
            : [];
        let opponentFilterValues = searchFilters.opponents
            ? searchFilters.opponents.map((opponent) => opponent.value)
            : [];
        let seasonsFilterValues = searchFilters.seasons
            ? searchFilters.seasons.map((season) => season.value)
            : [];
        let sportsFilterValues = searchFilters.sports
            ? searchFilters.sports.map((sport) => sport.value)
            : [];
        let statusesFilterValues = searchFilters.statuses
            ? searchFilters.statuses.map((status) => status.value)
            : [];
        return (
            <div
                style={{
                    marginTop: '25px',
                }}
            >
                <Query
                    query={contestsQuery}
                    variables={{
                        user_id: user.id,
                        orgid: user.orgid,
                        academicYears: academicYearsFilterValues,
                        homeOrAway:
                            searchFilters.homeOrAway &&
                            searchFilters.homeOrAway.value,
                        opponents: opponentFilterValues,
                        past: searchFilters.past,
                        seasons: seasonsFilterValues,
                        sports: sportsFilterValues,
                        statuses: statusesFilterValues,
                    }}
                    notifyOnNetworkStatusChange
                    fetchPolicy="network-only"
                >
                    {({ loading, error, data, refetch, networkStatus }) => {
                        if (networkStatus === 4) {
                            return <div>Refetching</div>;
                        }
                        if (loading) {
                            return <Spinner />;
                        }
                        if (error) {
                            return <div>Something went wrong</div>;
                        }
                        // let opponents = [];
                        // data.contests.forEach((contest) => {
                        //     let opponentId = contest.orgid === this.props.user.orgid ? contest.away_orgid : contest.orgid;
                        //     if (!opponents.includes(opponentId)) {
                        //         opponents.push(opponentId)
                        //     }
                        // })
                        // let opponentOptions = opponents.map((opponentId) => ({
                        //     text: this.props.orgs[opponentId],
                        //     value: opponentId,
                        // })).sort((a, b) => {
                        //     return a.text < b.text ? -1 : 1
                        // })

                        return (
                            <div
                                ref={this.handleContextRef}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Sticky context={contextRef} offset={70}>
                                        <ScheduleActions
                                            history={history}
                                            contests={data.contests}
                                            permissions={permissions}
                                            orgs={orgs}
                                            user={user}
                                            refetch={() => refetch()}
                                        />
                                        <ScheduleFilters
                                            notify={this.notify}
                                            opponentOptions={opponentOptions}
                                            orgs={orgs}
                                            permissions={permissions}
                                            user={user}
                                            filters={searchFilters}
                                            updateFilter={(filter, value) =>
                                                this.updateFilter(
                                                    filter,
                                                    value,
                                                    refetch
                                                )
                                            }
                                            resetFilters={this.resetFilters}
                                        />
                                    </Sticky>
                                </div>
                                <div
                                    style={{
                                        flex: 6,
                                        paddingLeft: '20px',
                                        paddingRight: '10px',
                                        paddingBottom: '30px',
                                    }}
                                >
                                    <ScheduleGrid
                                        contests={data.contests}
                                        permissions={permissions}
                                        history={history}
                                        user={user}
                                        orgs={orgs}
                                        contestsRefetch={refetch}
                                        onShowModal={(modal, contest) => {
                                            this.onShowModal(
                                                modal,
                                                contest,
                                                refetch
                                            );
                                        }}
                                    />
                                </div>
                                <EditContestModal
                                    contest={editingContest}
                                    contestsRefetch={refetch}
                                    open={editContestModalVisible}
                                    onClose={() =>
                                        this.setState({
                                            editContestModalVisible: false,
                                            editingContest: {},
                                        })
                                    }
                                    permissions={permissions}
                                    header="Edit Contest"
                                    user={user}
                                    orgs={orgs}
                                    actions={[]}
                                    viewUploadedContract={() => {
                                        this.setState({
                                            viewSignedModalVisible: true,
                                            editContestModalVisible: false,
                                        });
                                    }}
                                />
                                <SubmitContestModalContainer
                                    contest={editingContest}
                                    contestsRefetch={() => {
                                        refetch();
                                    }}
                                    open={submitContestModalVisible}
                                    onClose={() =>
                                        this.setState({
                                            submitContestModalVisible: false,
                                            editingContest: {},
                                        })
                                    }
                                    header="Send Contract"
                                    orgs={orgs}
                                    user={user}
                                />
                                <ContestStatusModalContainer
                                    contest={editingContest}
                                    contestsRefetch={() => refetch()}
                                    open={contractStatusModalVisible}
                                    onClose={() => {
                                        this.setState({
                                            contractStatusModalVisible: false,
                                            editingContest: {},
                                        });
                                    }}
                                    user={user}
                                    orgs={orgs}
                                />
                                <ViewSignedModal
                                    contest={editingContest}
                                    open={viewSignedModalVisible}
                                    onClose={() =>
                                        this.setState({
                                            viewSignedModalVisible: false,
                                        })
                                    }
                                />
                                <RequestContractModal
                                    contest={editingContest}
                                    open={requestContractModalVisible}
                                    user={user}
                                    onClose={() =>
                                        this.setState({
                                            requestContractModalVisible: false,
                                            editingContest: {},
                                        })
                                    }
                                />
                            </div>
                        );
                    }}
                </Query>
            </div>
        );
    }
}

export default Schedule;
